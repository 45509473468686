import React from 'react'
import { Link, graphql } from 'gatsby'
import { PortableText } from '@portabletext/react'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Container from '../components/container'

import ActivityCard from '../components/activity-card'

import BackArrow from '../images/back.inline.svg';
import ExternalLink from '../../static/images/external-link.inline.svg'
import Marker from '../../static/images/marker.inline.svg'

import { blockContentToText, mapEdgesToNodes } from '../utilities/helpers'
import { buildImageObject } from '../utilities/helpers'
import { imageUrlFor } from '../utilities/image-url'

import clsx from 'clsx'

import Marks from '../components/portable-text/marks'

/*
 * "Hack" to prevent background color classes from being purged.
 *
 * <Hackerman className={`
 *  bg-pink
 *  bg-pink-dark
 *  bg-orange
 *  bg-orange-dark
 *  bg-blue
 *  bg-blue-dark
 * `} />
 *
 * <Hackerman2 className={`
 *  [--vkd-underline-color:var(--vkd-blue-dark)]
 *  [--vkd-underline-color:var(--vkd-orange-dark)]
 *  [--vkd-underline-color:var(--vkd-pink-dark)]
 * `} />
 */

const ActivityTemplate = props => {
  const { data } = props

  const activity = data && data.activity
  const related_activities = data.related_activities && mapEdgesToNodes(
    data.related_activities
  )
  const { disclaimer } = data?.settings

  const formatter = new Intl.ListFormat('no', { style: 'long', type: 'conjunction' });
  const organizers = activity.organizer && activity.organizer.map((organizer) => {
    return organizer.name;
  })

  const color = activity.category.colors.title.replace('bg-', '');

  return (
    <Layout>
      <Seo
        title={activity.title}
        description={blockContentToText(activity.lead)}
        image={activity.image}
      />
      <Container className={clsx(
        `[--vkd-underline-color:var(--vkd-${color}-dark)]`,
        `${activity.category.colors.title} mb-[32px] md:mb-[100px]`
      )} stretch={true}>
        <Header className={`mb-[32px] md:mb-[80px]`} />
        <Container className={`pb-[32px] md:pb-[100px]`}>
          <div className={`flex flex-col md:grid md:grid-flow-col md:grid-cols-12`}>
            <div className={`md:col-start-1 md:col-span-7 mb-[30px] md:mb-0`}>
              { activity.visibility === ('both' || 'activities') ? (
                <Link className={`text-base font-bold inline-flex items-center mb-[16px]`} to={`/aktiviteter/`}>
                  <BackArrow />
                  <span className={`pl-[12px]`}>Tilbake til alle aktiviteter</span>
                </Link>
              ) : (
                <Link className={`text-base font-bold inline-flex items-center mb-[16px]`} to={`/kart/${activity.slug.current}`}>
                  <BackArrow />
                  <span className={`pl-[12px]`}>Tilbake til kartet</span>
                </Link>
              )}
              <h1 className={`flex items-center text-title md:text-title-md font-bold`}>
                <div className={`shrink-0 mr-[16px] bg-black text-white rounded-full flex items-center justify-center text-base w-[2em] h-[2em] md:text-[24px] font-bold`}>
                  {activity.document_id}
                </div>
                <span>{ activity.title }</span>
              </h1>
              <div className={`grid grid-cols-2 mb-[32px] gap-x-[32px]`}>
                {organizers && (
                  <div>
                    <div className={`font-normal text-small md:text-small-md mb-[4px]`}>Arrangert av:</div>
                    <div className={`font-bold`}>{ formatter.format(organizers) }</div>
                  </div>
                )}
                <div>
                  <div className={`font-normal text-small md:text-small-md mb-[4px]`}>Lokasjon:</div>
                  <Link to={`/kart/${activity.slug.current}`}>
                    <div className={`font-bold flex items-center`}>
                      <Marker />
                      <span className={`pl-[10px]`}>{activity.category.title}</span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className={`text-base md:text-base-md`}>
                <PortableText value={activity.description} components={{
                  marks: Marks
                }} />
              </div>
            </div>
            {activity.image && activity.image.asset && (
              <div className={`md:col-start-8 md:col-span-5 flex items-center justify-center`}>
                <div className={`activity-image w-full`}>
                  <figure>
                      <img
                        className={`max-h-[350px] max-w-[350px] w-full h-full`}
                        src={imageUrlFor(buildImageObject(activity.image)).url()}
                        alt={activity.image.alt} />
                  </figure>
                </div>
              </div>
            )}
          </div>
        </Container>
        {disclaimer && (
          <section className={`${activity.category.colors.title}-dark text-white text-center`}>
            <Container className={`py-[15px] md:py-[24px]`}>
              {disclaimer}
            </Container>
          </section>
        )}
      </Container>

      {activity.organizer_description && (
        <Container>
          <div className={clsx(
            `[--vkd-underline-color:var(--vkd-blue)]`,
            `mb-[60px] md:mb-[80px] px-[15px] py-[30px] md:px-[120px] md:py-[64px] bg-blue-light`
          )}>
            <div className={`max-w-activity-details`}>
              <div className={`mb-[12px]`}>
                {activity.organizer_logo && activity.organizer_logo.asset && (
                  <img
                    className={`float-left`}
                    src={
                      imageUrlFor(buildImageObject(activity.organizer_logo))
                        .height(64)
                        .url()
                    }
                    alt={activity.organizer_logo.alt} />
                )}
                {organizers && (
                  <>
                    <div className={`font-[450] text-small md:text-small-md mb-[10px]`}>
                      { activity.title } er arrangert av:
                    </div>
                    <h4 className={`text-h3 md:text-h3-md font-bold`}>
                      { formatter.format(organizers) }
                    </h4>
                  </>
                )}
              </div>

              <div className={`text-base md:text-base-md clear-both mb-[24px] max-w-prose`}>
                <PortableText value={activity.organizer_description} components={{
                  marks: Marks
                }} />
              </div>


              {activity.supportText && (
                <div className={`text-small md:text-small-md pt-[12px]`}>
                  { activity.supportText}
                </div>
              )}

            </div>

            {activity.organizer && activity.organizer.length && (
              <div className={`text-small mt-[60px]`}>
                {activity.organizer.map((organizer) => (
                  <div key={organizer.id} className={`mb-[30px] last:mb-0`}>
                    {activity.organizer.length > 1 ? (
                      <h5 className={`text-small mb-[10px]`}>
                        {organizer.name}
                      </h5>
                    ) : (
                      <></>
                    )}
                    {organizer && organizer.links && organizer.links.length > 0 && (
                      <div className={`flex flex-row flex-wrap`}>
                        {organizer.links.map((link, index) => (
                          <a key={index} className={`mr-[10px] mb-[10px] flex items-center p-[12px_16px] rounded-[8px] bg-black text-white`} href={link.url}>
                            <span className={`pr-[12px]`}>{link.title}</span>
                            <ExternalLink />
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Container>
      )}

      {related_activities && related_activities.length > 0 && (
        <Container>
          <h2 className="text-h3 md:text-h3-md font-bold mb-[24px]">
            Andre aktivitieter
          </h2>
          <div className={`mb-[80px]`}>
            <section className={`activity-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px] md:gap-8`}>
              {related_activities && related_activities.map((activity, index) => (
                <ActivityCard key={index} {...activity} />
              ))}
            </section>
          </div>
        </Container>
      )}
      <Footer />
    </Layout>
  )
}

export default ActivityTemplate

export const query = graphql`
  query ActivityPageQuery($id: String!, $category: String!) {
    activity: sanityActivity(id: {eq: $id}) {
      ...ActivityFields
    },
    related_activities: allSanityActivity(
      limit: 3,
      filter: {
        id: {
          ne: $id
        }
        slug: {
          current: {
            ne: null
          }
        }
        category: {
          slug: {
            current: {
              eq: $category
            }
          }
        }
        location: {
          lat: {
            ne: null
          },
          lng: {
            ne: null
          }
        }
      }
    ) {
      edges {
        node {
          ...ActivityFields
        }
      }
    },
    settings: sanitySettings {
      disclaimer
    }
  }
`
